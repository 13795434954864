
/*=============================================
=            hero slider            =
=============================================*/
 
  
.hero-alider-area{

    .ht-swiper-button{
        &-nav{
            background-color: rgba(255, 255, 255, 0.3);
            border: 0;
            color: $white;
    
            &:hover{
                background-color: $theme-color--default;
            }
        }
    
        &-prev{
            left: 100px;
    
            @media #{$laptop-device}{
                left: 20px;
            }
        }
    
        &-next{
            right: 100px;
    
            @media #{$laptop-device}{
                right: 20px;
            }
        }
    }


    .swiper-slide-active{
        .hero-slider__content{
            & > * {
                &:nth-child(1) {
                    animation-name: fadeInDown;
                    animation-duration: 1.5s;
                    animation-delay: 0.5s;
                }

                &:nth-child(2) {
                    animation-name: fadeInLeft;
                    animation-duration: 1.5s;
                    animation-delay: 1s;
                }
                &:nth-child(3) {
                    animation-name: fadeInDown;
                    animation-duration: 1.5s;
                    animation-delay: 2.5s;
                }
            }
        }
    }

}

.hero-slider{
   
    &__single-item{
        width: 100vw !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;
    }

    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 730px;
        width: 580px;
        max-width: 100%;

        @media #{$laptop-device, $desktop-device}{
            height: 600px;
        }

        @media #{$tablet-device}{
            height: 550px;
        }

        @media #{$large-mobile}{
            height: 450px;
        }

        & > * {
            animation-name: fadeInUp;
            animation-duration: 1s;
            animation-fill-mode: both;
        } 
    }

    &__title{
        font-size: 70px;
        line-height: 1.2;

        color: $white;
        text-shadow: 0 1px 0 #ccc, 
               0 2px 0 rgba(17, 108, 182, 0.59),
               0 3px 0 #bbb,
               0 4px 0 #b9b9b9,
               0 5px 0 #aaa,
               0 6px 1px rgba(0,0,0,.1),
               0 0 5px rgba(0,0,0,.1),
               0 1px 3px rgba(0,0,0,.3),
               0 3px 5px rgba(0,0,0,.2),
               0 5px 10px rgba(0,0,0,.25),
               0 10px 10px rgba(0,0,0,.2),
               0 20px 20px rgba(0,0,0,.15);

        margin-bottom: 40px;
        margin-top: -15px;

        @media #{$laptop-device}{
            font-size: 60px;
            margin-top: -12px;
        }

        @media #{$desktop-device}{
            font-size: 60px;
            margin-top: -12px;
        }

        @media #{$tablet-device}{
            font-size: 50px;
            margin-top: -8px;
        }

        @media #{$large-mobile}{
            font-size: 40px;
            margin-top: -8px;
            margin-bottom: 20px;
        }

        @media #{$small-mobile}{
            font-size: 35px;
            margin-bottom: 30px;
        }
    }

    &__text{
        font-size: 18px;
        line-height: 1.8; 
  
        color: $white;
        
        text-shadow: 3px 3px 7px rgba(31, 36, 128, 0.89),11px -12px 15px rgba(126, 74, 118, 0.24),-7px 4px 12px rgba(17, 108, 182, 0.59);

        margin-bottom: 50px;
        @media #{$large-mobile}{
            margin-bottom: 30px;
        }
        @media #{$small-mobile}{
            margin-bottom: 40px;
        }
    }

    &__btn{
        font-size: 16px;
        line-height: 2;
        padding: 20px 60px;
        
        color: $white;
        border: 2px solid $white;
         font:bold;
        align-self: flex-start;
        border-radius: 50px;
        transition: $transition--cubic;
        box-shadow: 20px 20px 50px lightyellow;
        text-shadow: 0px 11px 10px rgba(81,67,21,0.8);
        background-color:#68696cc5;

        &--style2{
            align-self: center;
        }

        &:hover, &:focus, &:active{
            color: $white;
            background-color: $theme-color--default;
            border-color: $theme-color--default;
        }
    }
}

/*=====  End of hero slider  ======*/

